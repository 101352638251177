var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'explorer__start__no_bg mx-20 my-14': _vm.fullScreen,
    'flex flex-col': !_vm.fullScreen,
  }},[(_vm.logos.length > 1)?[_c('SvgLogo',{staticClass:"welcome-explorer__register--logo",attrs:{"logo":_vm.logos[1],"svg-width":"150","left":"","alt":"explorer icon"}}),_c('ImageAtom',{staticClass:"deg-waiting-list--line mt-4",attrs:{"source":require('@/assets/waitingList/dge-line.svg'),"alt-text":'dge-line'}})]:_vm._e(),_c('TextAtom',{staticClass:"text-left w-full md:w-[70%]",class:_vm.fullScreen ? 'mt-8' : '',attrs:{"value":_vm.title,"color":'primary-dark',"font":"poppins","weight":"400","size":_vm.mobile ? 'subtitle' : 'big'}}),_c('div',{class:_vm.fullScreen ? 'my-20' : 'flex-grow flex flex-col items-center justify-center'},_vm._l((_vm.consents),function(consent){return _c('div',{key:consent.code,staticClass:"flex space-x-4 items-center mt-3 rounded-lg bg-light p-4 h-fit-content"},[_c('CheckboxInput',{key:consent.code,model:{value:(consent.accepted),callback:function ($$v) {_vm.$set(consent, "accepted", $$v)},expression:"consent.accepted"}}),_c('TextAtom',{staticClass:"text-left",attrs:{"value":_vm.getText(consent, 'intro'),"i18n-args":{
          title: consent.title,
          url: consent.pdf_url,
        },"size":_vm.fullScreen ? 'big' : undefined},scopedSlots:_vm._u([(!consent.required)?{key:"pre",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('user_consent.optional')))])]},proxy:true}:null,{key:"post",fn:function(){return [_c('a',{attrs:{"href":consent.pdf_url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.getText(consent, 'title'))+" ")])]},proxy:true}],null,true)})],1)}),0),_vm._t("actions",function(){return [_c('div',{staticClass:"register-main inline-block justify-between",class:_vm.mobile ? 'welcome-explorer__continue--mobile' : 'welcome-explorer__continue'},[_c('div',{staticClass:"space-x-4 items-center"},[_vm._t("submission-button",function(){return [_c('NextButtonResearch',{staticClass:"px-4",attrs:{"disabled":_vm.missingConsents},on:{"click":_vm.submit}},[(_vm.submitLabel)?[_vm._v(" "+_vm._s(_vm.$t(_vm.submitLabel))+" ")]:_vm._e()],2)]},{"submit":_vm.submit,"reject":_vm.reject,"missingConsents":_vm.missingConsents})],2)])]},{"submit":_vm.submit,"reject":_vm.reject,"missingConsents":_vm.missingConsents})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }