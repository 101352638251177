<template>
  <div
    :class="{
      'explorer__start__no_bg mx-20 my-14': fullScreen,
      'flex flex-col': !fullScreen,
    }"
  >
    <template v-if="logos.length > 1">
      <SvgLogo
        :logo="logos[1]"
        svg-width="150"
        left
        alt="explorer icon"
        class="welcome-explorer__register--logo"
      />
      <ImageAtom
        class="deg-waiting-list--line mt-4"
        :source="require('@/assets/waitingList/dge-line.svg')"
        :alt-text="'dge-line'"
      />
    </template>
    <TextAtom
      :value="title"
      :color="'primary-dark'"
      font="poppins"
      weight="400"
      :size="mobile ? 'subtitle' : 'big'"
      class="text-left w-full md:w-[70%]"
      :class="fullScreen ? 'mt-8' : ''"
    />
    <div
      :class="fullScreen ? 'my-20' : 'flex-grow flex flex-col items-center justify-center'"
    >
      <div
        v-for="consent in consents"
        :key="consent.code"
        class="flex space-x-4 items-center mt-3 rounded-lg bg-light p-4 h-fit-content"
      >
        <CheckboxInput
          :key="consent.code"
          v-model="consent.accepted"
        />
        <TextAtom
          :value="getText(consent, 'intro')"
          :i18n-args="{
            title: consent.title,
            url: consent.pdf_url,
          }"
          class="text-left"
          :size="fullScreen ? 'big' : undefined"
        >
          <template v-if="!consent.required" #pre>
            <span>{{ $t('user_consent.optional') }}</span>
          </template>
          <template #post>
            <a :href="consent.pdf_url" target="_blank" rel="noopener noreferrer">
              {{ getText(consent, 'title') }}
            </a>
          </template>
        </TextAtom>
      </div>
    </div>
    <slot
      name="actions"
      :submit="submit"
      :reject="reject"
      :missing-consents="missingConsents"
    >
      <div
        class="register-main inline-block justify-between"
        :class="mobile ? 'welcome-explorer__continue--mobile' : 'welcome-explorer__continue'"
      >
        <div
          class="space-x-4 items-center"
        >
          <!-- <slot
            name="rejection-button"
          >
            <TextAtom
              value="user_consent.reject"
              class="cursor-pointer"
              @click="reject"
            />
          </slot> -->
          <slot
            name="submission-button"
            :submit="submit"
            :reject="reject"
            :missing-consents="missingConsents"
          >
            <NextButtonResearch
              :disabled="missingConsents"
              class="px-4"
              @click="submit"
            >
              <template v-if="submitLabel">
                {{ $t(submitLabel) }}
              </template>
            </NextButtonResearch>
          </slot>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import userRegistrationService from '@/services/userRegistration.services';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CheckboxInput from '@/components/atoms/inputs/CheckboxInput.vue';
import NextButtonResearch from '@/components/atoms/buttons/NextButtonResearch.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'UserConsent',
  components: {
    SvgLogo,
    ImageAtom,
    TextAtom,
    CheckboxInput,
    NextButtonResearch,
  },
  props: {
    logos: {
      type: Array,
      default: () => [],
    },
    consentCodes: {
      type: Array,
      default: () => [
        'tether_base',
      ],
    },
    title: {
      type: String,
      default: 'user_consent.title',
    },
    submitLabel: {
      type: String,
      default: undefined,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      loading: true,
      submitting: false,
      consents: [],
    };
  },
  computed: {
    ...mapGetters({
      userUsername: 'authentication/userUsername',
      campuses: 'elasticSearch/campus',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    missingConsents() {
      return this.consents.some((consent) => consent.required && !consent.accepted) || this.submitting || this.loading;
    },
    campus() {
      return this.campuses?.length === 1 ? this.campuses[0] : undefined;
    },
    getText() {
      return (consent, key) => {
        if (consent[key]) {
          return this.replaceText(consent[key]);
        }
        if (this.$te(`user_consent.${consent.code}.${key}`)) {
          return `user_consent.${consent.code}.${key}`;
        }
        return null;
      };
    },
  },
  watch: {
    consentCodes: {
      handler() {
        this.fetchConsents();
      },
      immediate: true,
    },
  },
  methods: {
    fetchConsents() {
      this.loading = true;
      userRegistrationService.getTermsAndConditions(this.consentCodes, this.campus?.campus_code).then((response) => {
        this.consents = response.data.map((consent) => ({
          ...consent,
          accepted: consent.status === 'accepted',
        })).sort((a, b) => this.consentCodes.indexOf(a.code) - this.consentCodes.indexOf(b.code));
      }).finally(() => {
        this.loading = false;
      });
    },
    submit() {
      this.submitting = true;
      Promise.all(this.consents.map((consent) => userRegistrationService.submitTermsAndCondition(consent.code, {
        accepted: consent.accepted,
        campusCode: this.campus?.campus_code,
      }))).then(() => {
        this.$emit('submitted');
      }).finally(() => {
        this.submitting = false;
      });
    },
    reject() {
      this.$emit('rejected');
    },
    replaceText(rawText) {
      const replacements = {
        '{name}': this.userUsername,
        '{partner}': this.partner?.name,
        '{institution}': this.campus?.name,
      };
      return Object.entries(replacements)
        .filter(([, value]) => Boolean(value))
        .reduce((text, [key, value]) => text.replace(key, value), rawText);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
